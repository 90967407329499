(function() {
  'use strict';

  PartialTopCampaignVideoCtrl.$inject = ["$sce"];
  angular
    .module('Suzuki')
    .controller('PartialTopCampaignVideoCtrl', PartialTopCampaignVideoCtrl);

  /* @ngInject */
  function PartialTopCampaignVideoCtrl( $sce) {
    var vm = this;
    vm.isActive = false;
    vm.youtubeUrl = '';
    vm.youtubeId = '';
    vm.activate = function() {
      var videoUrl = '<iframe class="embed-responsive-item" type="text/html" src="//www.youtube.com/embed/' + vm.youtubeId + '?modestbranding=1&rel=0&showinfo=0&autohide=1&wmode=opaque&autoplay=1" frameborder="0" allowfullscreen></iframe>';
      vm.youtubeUrl = $sce.trustAs($sce.HTML, videoUrl);
      vm.isActive = true;
    };
  }
})();
