(function () {
  'use strict';

  faqService.$inject = ["$http"];
  angular
    .module('Suzuki')
    .service('faqService', faqService);

  /* @ngInject */
  function faqService($http) {

    // -------------------------------------------------------------------------
    // Public Method & Variables
    // -------------------------------------------------------------------------

    /*jshint validthis:true */
    this.submit = submit;

    function submit(model) {

      console.info('FAQ', model);

      return $http({
        url: '/api/mail/FaqQuestion',
        data: model,
        method: 'POST'
      });
    }

  }
})();
