(function(undefined) {
    'use strict';

    angular
        .module('Suzuki', [
            'ngSanitize',
            'ngTouch',
            'ngAnimate',
            'ui.bootstrap',
            'ui.scrollpoint',
            'duScroll',
            'rt.debounce',
            'uiGmapgoogle-maps',
            'LocalStorageModule',
            'djds4rce.angular-socialshare',
            'ngFileUpload'
        ])

        .value('duScrollDuration', 1000)
        .value('duScrollOffset', 70)
        .value('duScrollGreedy', true)

        .config(["$provide", "uiGmapGoogleMapApiProvider", "localStorageServiceProvider", function($provide, uiGmapGoogleMapApiProvider, localStorageServiceProvider) {

            uiGmapGoogleMapApiProvider.configure({
                //key: 'your api key',
                v: '3.20',
                //sensor: true,
                //libraries: 'weather,geometry,visualization'
                language: 'da',
                'signed_in': true
            });

            localStorageServiceProvider
                .setPrefix('suzuki.dk')
                .setNotify(true, true);

            // Remove Facebook template
            $provide.decorator('facebookDirective', ["$delegate", function($delegate) {
                $delegate[0].transclude = false;
                $delegate[0].template = null;
                return $delegate;
            }]);

            // Rewrite ui.bootstrap template url's
            $provide.decorator('tabDirective', ["$delegate", function($delegate) {
                $delegate[0].templateUrl = '/assets/js/angular/templates/tabs/tab.html';
                return $delegate;
            }]);
            $provide.decorator('tabsetDirective', ["$delegate", function($delegate) {
                $delegate[0].templateUrl = '/assets/js/angular/templates/tabs/tabset.html';
                return $delegate;
            }]);
            $provide.decorator('accordionGroupDirective', ["$delegate", function($delegate) {
                $delegate[0].templateUrl = '/assets/js/angular/templates/accordion/accordion-group.html';
                return $delegate;
            }]);
            $provide.decorator('accordionDirective', ["$delegate", function($delegate) {
                $delegate[0].templateUrl = '/assets/js/angular/templates/accordion/accordion.html';
                return $delegate;
            }]);
        }])

        .run(["$rootScope", "$window", "$document", "debounce", "$FB", function($rootScope, $window, $document, debounce, $FB) {
            //console.log('Suzuki Yay!', $window.location.hash);
            //Facebook
            $FB.init('230384207117241');

            // smoothscroll internal links
            $($document).ready(function() {
                $('.rte a[href^="#"]').on('click', function() {
                    $document.scrollToElementAnimated($($(this).attr('href')));
                    return false;
                });
            });

            // Setup Scrollspy
            if (!window.history || !history.replaceState) {
                return;
            }

            angular.element($window).bind('load', function() {
                setupScrollSpy();
            });

            var updateHash = debounce(500, doUpdateHash);

            function doUpdateHash(id) {
                if (!id) {
                    return;
                }

                var hash = id !== 'suzuki' ? '#' + id : $window.location.pathname;
                history.replaceState(null, null, hash);
            }

            function setupScrollSpy() {
                $rootScope.$on('duScrollspy:becameActive', function($event, $element) {
                    updateHash($element.prop('id') || $element.prop('href').substring($element.prop('href').indexOf('#') + 1));
                });
            }
        }]);

    // angular.bootstrap(document, ['Suzuki']);

})();
