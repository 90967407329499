(function () {
  'use strict';

  angular
    .module('Suzuki')
    .directive('modal', modal);

  /* @ngInject */
  function modal() {
    var directive = {
      link: link,
      restrict: 'A'
    };
    return directive;

    function link() {
      Suzuki.Modal.init();
    }
  }
})();
