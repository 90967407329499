(function (undefined) {
  'use strict';

  /**
   * @ngdoc overview
   * @name Suzuki - MainController
   * @description
   * # Suzuki
   *
   * Main module of the application.
   */

  MainController.$inject = ["trackingService"];
  angular
    .module('Suzuki')
    .controller('MainController', MainController);

  /* @ngInject */
  function MainController(trackingService) {

    /* jshint validthis:true */
    var vm = this;

    vm.trackEvent = function (category, action, optlabel) {
      trackingService.trackEvent(category, action, optlabel);
    };

  }
})();

