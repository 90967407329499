(function () {
  'use strict';

  angular
    .module('Suzuki')
    .directive('linkButtons', linkButtons);

  /* @ngInject */
  function linkButtons() {
    return {
      link: link,
      restrict: 'A'
    };

    function link(scope, element) {

      var links = element.find('a:not(:has(img))');

      links.each(function (index, link) {
        $(link).addClass('btn');
      });
    }
  }
})();
