(function (undefined) {
  'use strict';

  /**
   * @ngdoc overview
   * @name Suzuki - ScrollpointController
   * @description
   * # Suzuki
   *
   * Scrollpoint module of the application.
   */

  ScrollpointCtrl.$inject = ["$scope", "$window", "$timeout"];
  angular
    .module('Suzuki')
    .controller('ScrollpointCtrl', ScrollpointCtrl);

  /* @ngInject */
  function ScrollpointCtrl($scope, $window, $timeout) {

    /* jshint validthis:true */
    var vm = this;

    vm.active = false;

    $($window).on('load', function () {
      $timeout(function() {
        $scope.$broadcast('scrollpointShouldReset');
      });
    });

    vm.scrollTop = function (distance) {
      // scroll events are triggered by DOM, so use $scope.$apply();
      $scope.$apply(function() {
        vm.active = (distance > 0);
      });
    };

    vm.scrollBottom = function (distance) {
      // scroll events are triggered by DOM, so use $scope.$apply();
      $scope.$apply(function() {
        vm.active = (distance <= 0);
      });
    };
  }
})();
