(function() {
    'use strict';

    angular
        .module('Suzuki')
        .directive('partialMotor', partialMotor);



    function partialMotor() {
        // Usage:
        //     <motor></motor>
        // Creates:
        //
        controller.$inject = ["$scope", "$sce"];
        var directive = {
            link: link,
            controller:controller,
            restrict: 'EA',
            scope: true
        };
        return directive;

        function link(scope, element ) {


            var items = $(element).find('.menu li');
            var item;

            scope.activeItem = 0;

            scope.activate = function(index) {
                scope.activeItem = index;
            };

            scope.isActive = function(index) {
                return index === scope.activeItem;
            };

            scope.$watch('activeItem', function () {
                item = $(items[scope.activeItem]);
                scope.createVideoUrl(item.data('video'));
                scope.imageUrl = item.data('image');
                scope.hasVideo = item.data('hasvideo') === 'True';
                scope.bodyText = item.data('text');
                scope.tabledata = item.find('.table-data').html();
            });
        }

        /* @ngInject */
        function controller($scope, $sce) {
            $scope.createVideoUrl = function(videoId) {
                var iframe = '<div class="embed-responsive embed-responsive-16by9"><iframe id="ytplayer7" type="text/html" width="580" height="380" src="https://www.youtube.com/embed/' + videoId + '?autoplay=0" frameborder="0"></iframe></div>';
                $scope.videoUrl = $sce.trustAs($sce.HTML, iframe);
            };
        }
    }

})();

            