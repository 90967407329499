(function () {
  'use strict';

  trackingService.$inject = ["$window"];
  angular
    .module('Suzuki')
    .service('trackingService', trackingService);

  /* @ngInject */
  function trackingService($window) {

    // -------------------------------------------------------------------------
    // Public Method & Variables
    // -------------------------------------------------------------------------

    /*jshint validthis:true */
    this.trackEvent = trackEvent;

    function trackEvent(category, action, optlabel) {
      if (typeof $window.dataLayer !== 'undefined') {
        $window.dataLayer.push({ 'event': 'track', 'eventCategory': category, 'eventAction': action, 'eventLabel': optlabel });
        //console.info('trackEvent', category, action, optlabel);
      }
    }
  }
})();
