(function () {
  'use strict';

  TestdriveCtrl.$inject = ["$scope", "$http", "$window", "$document", "$timeout", "dealers", "trackingService"];
  angular
    .module('Suzuki')
    .controller('TestdriveCtrl', TestdriveCtrl);

  /* @ngInject */
  function TestdriveCtrl($scope, $http, $window, $document, $timeout, dealers, trackingService) {

    //console.log('TestdriveCtrl');

    reset();
    init();

    // -------------------------------------------------------------------------
    // Public Methods
    // -------------------------------------------------------------------------

    $scope.select = select;
    $scope.isActive = isActive;
    $scope.back = back;
    $scope.chooseDealer = chooseDealer;
    $scope.searchDealer = searchDealer;
    $scope.order = order;

    // -------------------------------------------------------------------------
    // Methods
    // -------------------------------------------------------------------------

    function init() {
      if ($window.location.hash) {
        var hash = $window.location.hash;
        var elem = angular.element(hash);

        $timeout(function() {
          elem.trigger('click');
        });
      }
    }

    // -------------------------------------------------------------------------

    function reset() {
      $scope.step = 0;
      $scope.model = {};
      $scope.zipcode = null;
      $scope.dealers = [];
      $scope.user = {};

      if ($scope.form) {
        $scope.form.$setPristine();
        $scope.form.$setUntouched();
      }
    }

    // -------------------------------------------------------------------------

    function select(id, title, thumb, urlName) {
      $scope.user.dealer = null;
      $scope.model = {
        id: id,
        title: title,
        thumb: thumb,
        urlName: urlName
      };
      $scope.step = 1;
      history.replaceState(null, null, '#' + urlName);
    }

    // -------------------------------------------------------------------------

    function isActive(id) {
      return $scope.model.id === id;
    }

    // -------------------------------------------------------------------------

    function back() {
      $scope.step = 1;
    }

    // -------------------------------------------------------------------------

    function chooseDealer() {
      $scope.zipcode = $scope.zipcode || $scope.user.zipcode;

      dealers.findDealers($scope.zipcode)
        .then(function(result) {
          $scope.dealers = result.data;
        })
        .finally(function() {
          $scope.step = 2;
          scrollToElement($('.choose-dealer'));
        });
    }

    // -------------------------------------------------------------------------

    function searchDealer() {
      $scope.user.dealer = null;
      $scope.zipcode = $scope.user.zipcodeSearch;

      dealers.findDealers($scope.zipcode)
        .then(function(result) {
          $scope.dealers = result.data;
        })
        .finally(function() {
          $scope.step = 2;
          scrollToElement($('.choose-dealer'));
        });
    }

    // -------------------------------------------------------------------------

    function order() {
      var payload = angular.copy($scope.user);

      payload.DealerNr = $scope.user.dealer.DealerNr;
      payload.TestCar = $scope.model.title;

      //console.log(payload);

      $http({
        method: 'POST',
        url: '/api/testdrive/booktestdrive/',
        data: payload
      }).success(function() {
        //console.log('success', data, status);
        trackingService.trackEvent(payload.TestCar, 'Prøvetur bestilling gennemført', 'Prøvetur');
        //dataLayer.push({ 'event': 'track', 'eventCategory': payload.TestCar, 'eventAction': 'Brochure bestilling gennemført', 'eventLabel': 'Brochure' });
      }).error(function() {
        //console.error('error', data, status);
      }).finally(function() {
        $scope.user.dealer = null;
        $scope.step = 3;
      });
    }

    // -------------------------------------------------------------------------

    function scrollToElement(elem) {
      $timeout(function() {
        $document.scrollToElementAnimated(elem, 80);
      });
    }
  }
})();


