(function (undefined) {
  'use strict';

  /**
   * @ngdoc overview
   * @name Suzuki - FaqController
   * @description
   * # Suzuki
   *
   * Faq module of the application.
   */

  FaqController.$inject = ["$scope", "$document", "$timeout", "faqService"];
  angular
    .module('Suzuki')
    .controller('FaqController', FaqController);

  /* @ngInject */
  function FaqController($scope, $document, $timeout, faqService) {

    /* jshint validthis:true */
    var vm = this;

    var lastQ;
    var scrollOffset = 80;

    vm.oneAtATime = true;
    vm.activeCat = {};
    vm.activeQ = {};

    vm.model = {};
    vm.success = false;

    vm.toggleCat = function(event) {
      vm.activeQ = {};
      scrollToElement(event.target);
    };

    vm.toggleQ = function(id, event) {
      if (vm.activeQ['q' + id] !== true) {
        lastQ = id;
        scrollToElement(event.target);
      }
    };

    vm.submit = function () {
      faqService.submit(vm.model).then(function () {
        vm.success = true;
      }, function (error) {
        console.error('error', error);

        vm.form.$setPristine();
        vm.form.$setUntouched();
      });
    };

    $scope.$watchCollection('vm.activeQ', function(newValue) {
      for (var key in newValue) {
        if (key !== ('q' + lastQ) && newValue.hasOwnProperty(key) && newValue[key] !== false) {
          newValue[key] = false;
        }
      }
    });

    function scrollToElement(elem) {
      $timeout(function() {
        $document.scrollToElementAnimated(elem, scrollOffset);
      }, 400);
    }
  }
})();

