(function () {
    'use strict';

    bookRepair.$inject = ["bookService"];
    angular
        .module('Suzuki')
        .directive('bookRepair', bookRepair);

    function bookRepair(bookService) {
        controller.$inject = ["$scope", "$element", "$attrs"];
        var directive = {
            restrict: 'A',
            controller: controller,
            controllerAs: 'bookRepairCtrl',
            bindToController: true,
        };
        return directive;

        /* @ngInject */
        function controller($scope, $element, $attrs) {

            angular.element(document).ready(function () {
                var someDate = new Date();
                var numberOfDaysToAdd = 7;
                someDate.setDate(someDate.getDate() + numberOfDaysToAdd);

                var dd = someDate.getDate();
                var mm = someDate.getMonth() + 1;
                var y = someDate.getFullYear();

                var someFormattedDate = dd + '-' + mm + '-' + y;

                $('[data-toggle="datepicker"]').datepicker({
                    inline: true,
                    container: '.datepickerContainer',
                    language: 'da-DK',
                    format: 'dd-mm-yyyy',
                    startDate: someFormattedDate,
                    filter: function (date) {
                        if (date.getDay() === 0 || date.getDay() === 6) {
                            return false; // Disable all Sundays and saturdays
                        }
                    }
                });
            });

            var vm = this,
                mapZoom = 7,
                mapCenter = {
                    lat: 56.163906,
                    lng: 11.085204
                };
            vm.success = false;
            vm.loading = false;

            vm.emailRegex = '^([A-Za-z0-9\-\_\.]{1,})+[@]([A-Za-z0-9\-\_]{1,})+[.]([A-Za-z\.]{2,5})$';
            vm.carError = {
                'one': false,
                'oneLicens': false,
                'oneMileage': false,
                'two': false,
                'three': false,
                'four': false,
                'fourDate': false,
                'five': false,
                'fivePhone': false,
                'fiveEmail': false
            };

            vm.carYear = undefined;
            vm.carModel = undefined;
            vm.carVariant = undefined;
            vm.date = undefined;
            vm.time = undefined;
            vm.searchText = {
                postalCoverage: undefined
            };

            vm.currentStep = 1;
            vm.dealers = [];
            vm.mapDealers = [];

            vm.services = {
                service: {
                    name: 'Serviceeftersyn',
                    value: undefined
                },
                rustCheck: {
                    name: 'Karrosseri-/rusteftersyn',
                    value: undefined
                },
                wheelChange: {
                    name: 'Hjul-/dækskifte',
                    value: undefined
                },
                freeTestCheck: {
                    name: 'Gratis synstjek',
                    value: undefined
                },
                other: {
                    name: 'Andet',
                    value: undefined
                }
            };

            vm.map = {
                center: {
                    latitude: mapCenter.lat,
                    longitude: mapCenter.lng
                },
                zoom: mapZoom,

                bounds: {},
                options: {
                    scrollwheel: false
                }
            };
            vm.geo = {
                id: 'geo',
                coords: {
                    latitude: mapCenter.lat,
                    longitude: mapCenter.lng
                },
                options: {
                    icon: '/assets/images/map/geo.png',
                    animation: 'DROP'
                },
                show: false
            };

            function anyChecked() {
                var serviceTypes = $element.find('.checkbox--service');
                return _.find(serviceTypes, function (o) {
                    return o.checked === true;
                });
            }

            vm.markerClick = function (marker, event, dealer) {
                vm.dealers = [];
                vm.dealers.push(dealer);
                vm.searchText.postalCoverage = dealer.postalCode;
            };

            var jsonDealers = JSON.parse($attrs.repairdealers);
            angular.forEach(jsonDealers, function (value) {
                var dealer = {
                    id: value.Id,
                    authorized: value.Authorized,
                    icon: value.Authorized ? '/assets/images/map/marker_dealer_authorized.png' : '/assets/images/map/marker_dealer.png',
                    latitude: value.Latitude,
                    longitude: value.Longitude,
                    position: new google.maps.LatLng(value.Latitude, value.Longitude),
                    title: value.Name,
                    address: value.Address,
                    phone: value.Phone,
                    link: value.WebPage,
                    postalCode: value.PostalCode,
                    postalCoverage: value.PostalCoverage,
                    city: value.City,
                    email: value.Email
                };

                vm.mapDealers.push(dealer);
                vm.dealers.push(dealer);
            });

            function nextStep() {
                vm.currentStep = vm.currentStep + 1;
                window.scrollTo(0, 0);
            }

            vm.searchTextClear = function () {
                if (vm.searchText.postalCoverage.length === 0) {
                    vm.dealers = vm.mapDealers;
                }
            };

            // vm.setWaitOrRent = function (type) {
            //     if (type === "waitForFix" && vm.rentCar) {
            //         vm.rentCar = false;
            //         vm.waitForFix = true;
            //     } else if (type === "rentCar" && vm.waitForFix) {
            //         vm.rentCar = true;
            //         vm.waitForFix = false;
            //     }
            // }

            function error(number) {
                vm.carError[number] = true;
                setTimeout(function () {
                    vm.carError[number] = false;
                }, 2500);
            }

            vm.refreshMap = false;
            vm.nextStep = function () {

                if (vm.currentStep === 1 && vm.bookService.LicencePlate.$invalid) {
                    error('oneLicence');
                } else if (vm.currentStep === 1 && (vm.bookService.Model.$invalid || vm.bookService.Year.$invalid)) {
                    error('one');
                } else if (vm.currentStep === 1 && vm.bookService.Mileage.$invalid) {
                    error('oneMileage');
                } else if (vm.currentStep === 3 && anyChecked() === undefined) {
                    error('three');
                } else if (vm.currentStep === 3 && vm.bookService.Date.$invalid) {
                    error('fourDate');
                } else if (vm.currentStep === 3 && vm.bookService.Time.$invalid) {
                    error('four');
                } else {
                    if (vm.currentStep === 1 || vm.currentStep === 2) {
                        vm.refreshMap = true;
                    }
                    nextStep();
                }
            };
            vm.bookServiceFn = function () {
                if (vm.currentStep === 4 && vm.bookService.Name.$invalid) {
                    error('five');
                } else if (vm.currentStep === 4 && vm.bookService.Phone.$invalid) {
                    error('fivePhone');
                } else if (vm.currentStep === 4 && vm.bookService.Email.$invalid) {
                    error('fiveEmail');
                } else {
                    vm.loading = true;
                    var formData = {
                        Model: vm.carModel,
                        Year: vm.carYear,
                        // Variant: vm.carVariant,
                        LicencePlate: vm.carLicencePlate,
                        Mileage: vm.carMileage,
                        Service: vm.services.service.value,
                        Rustcheck: vm.services.rustCheck.value,
                        WheelChange: vm.services.wheelChange.value,
                        FreeTestCheck: vm.services.freeTestCheck.value,
                        Other: vm.services.other.value,
                        Comments: vm.comments,
                        // RentCar: vm.rentCar,
                        // WaitForFix: vm.waitForFix,
                        WaitOrRent: vm.waitOrRent,
                        DealerName: vm.dealerName,
                        DealerAddress: vm.dealerAddress,
                        DealerCity: vm.dealerCity,
                        DealerPostal: vm.dealerPostalCode,
                        DealerPhone: vm.dealerPhone,
                        DealerEmail: vm.dealerEmail,
                        Date: vm.date,
                        time: vm.time,
                        Name: vm.customerName,
                        Phone: vm.customerPhone,
                        Email: vm.customerEmail
                    };

                    bookService.book(formData)
                        .then(function (result) {
                            console.log('result', result);
                            vm.loading = false;
                        })
                        .finally(function () {
                            console.log('finally');
                            vm.success = true;
                            vm.currentStep = 0;
                            vm.loading = false;
                        });
                }
            };

            vm.prevStep = function () {
                window.scrollTo(0, 0);
                vm.currentStep = vm.currentStep - 1;
            };

            vm.setDealerShop = function (dealer) {
                vm.dealerName = dealer.title;
                vm.dealerAddress = dealer.address;
                vm.dealerCity = dealer.city;
                vm.dealerPostalCode = dealer.postalCode;
                vm.dealerPhone = dealer.phone;
                vm.dealerEmail = dealer.email;
                nextStep();
            };
        }
    }
})();