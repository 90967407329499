
(function () {
  'use strict';

  var mosaikGallery;

  mosaikGallery = {
    RootElement: '',
    lightBox: '',
    clickBtn: function(){
      var firstLightBoxNode = mosaikGallery.RootElement.querySelector('.mosaik-gallery__item');
      mosaikGallery.lightBox.open(firstLightBoxNode);
    },
    /**
     * Initiate mosaikGallery with lightbox'
     *
     * @param {NodeList} elements  - Selector for btn elements
     */
    setupBtn: function(elements){

      for (var i = 0; i < elements.length; i++) {
        var element = elements.item(i);

        element.removeEventListener('click', mosaikGallery.clickBtn, false);
        element.addEventListener('click', mosaikGallery.clickBtn, false);
      }

    },
    /**
     * Initiate mosaikGallery with lightbox'
     *
     * @param {NodeList} elements  - Selector mosaik gallery element
     */
    initiate: function (elements) {

      mosaikGallery.lightBox = new GLightbox({
        selector: 'mosaik-gallery__item'
      });

      if(!elements) {
        return;
      }

      for (var i = 0; i < elements.length; i++) {
        var element = elements.item(i);
        mosaikGallery.RootElement = element;
        mosaikGallery.setupBtn(element.querySelectorAll('.mosaik-gallery__btn'));
      }
    }
  };

  /**
   * Call mosaikGallery and initiate all elements
   */
  (function () {
    mosaikGallery.initiate(document.querySelectorAll('.mosaik-gallery'));
  })();
})();
