(function () {
  'use strict';

  BrocuresMailorderCtrl.$inject = ["$scope", "$http", "trackingService"];
  angular
    .module('Suzuki')
    .controller('BrocuresMailorderCtrl', BrocuresMailorderCtrl);

  /* @ngInject */
  function BrocuresMailorderCtrl($scope, $http, trackingService) {

    //console.log('BrocuresMailorderCtrl');

    $scope.basket = {};
    $scope.basketReady = false;

    $scope.$watch('basket', function(newValue, oldValue) {
      var size = 0;
      var key;
      for (key in newValue) {
        if (newValue.hasOwnProperty(key) && newValue[key] !== false) {
          size++;
        }
      }

      if (size > 4) {
        $scope.basket = oldValue;
      }

      $scope.basketReady = size > 0;

    }, true);

    // -------------------------------------------------------------------------
    // Public Methods
    // -------------------------------------------------------------------------

    $scope.remove = remove;
    $scope.reset = reset;
    $scope.order = order;
    $scope.toggle = toggle;
    $scope.trackEvent = trackEvent;

    // -------------------------------------------------------------------------
    // Methods
    // -------------------------------------------------------------------------

    function toggle(model, type, label) {
      if ($scope.basket[model]) {
        trackEvent(type, label, true);
      }
    }

    // -------------------------------------------------------------------------

    function trackEvent(type, label, isMail) {
      var action = '';

      switch (type) {
        case '':
          action = 'øvrig brochure';
          break;
        case 'Model':
          action = 'modelbrochure';
          break;
        case 'Ekstraudstyr':
          action = 'ekstraudstyrsbrochure';
          break;
      }

      if (isMail) {
        action = 'Bestil ' + action + ' via post';
      } else {
        action = 'Se ' + action;
      }

      trackingService.trackEvent('brochure', action, label);
    }

    // -------------------------------------------------------------------------

    function remove(key) {
      delete $scope.basket[key];
    }

    // -------------------------------------------------------------------------

    function reset() {
      $scope.basket = {};
      $scope.user = {};
      $scope.basketReady = false;
      $scope.form.$setPristine();
      $scope.form.$setUntouched();
    }

    // -------------------------------------------------------------------------

    function order() {

      var payload = angular.copy($scope.user);
      payload.brochures = [];

      for (var key in $scope.basket) {
        if ($scope.basket.hasOwnProperty(key) && $scope.basket[key] !== false) {
          payload.brochures.push({ name: $scope.basket[key] });
        }
      }

      $http({
        method: 'POST',
        url: '/api/brochures/order/',
        data: payload
      }).success(function() {
        $scope.form.$submitted = true;
        //console.log('success', payload, status);
        //dataLayer.push({ 'event': 'track', 'eventCategory': 'Brochurer', 'eventAction': 'Brochure bestilling gennemført', 'eventLabel': 'Brochure' });
        trackingService.trackEvent('Brochurer', 'Brochure bestilling gennemført', 'Brochure');
      }).error(function(data, status) {
        console.error('error', data, status);
      });
    }
  }
})();
