(function () {
  'use strict';

  var pickerGallery;

  pickerGallery = {
    RootElement: '',
    activeTabClass: 'picker-gallery__tab--active',
    activeItemClass: 'picker-gallery__item--active',
    titleSwapClass: 'picker-gallery__tab-title-swap',
    cachedTitle: '',
    restoreTitle: function() {
      var title = pickerGallery.RootElement.querySelector('.' + pickerGallery.titleSwapClass);

      title.innerHTML = pickerGallery.cachedTitle;

    },
    setTitle: function () {
      var itemName = this.getAttribute('data-name');
      var title = pickerGallery.RootElement.querySelector('.' + pickerGallery.titleSwapClass);

      if (pickerGallery.cachedTitle === itemName) {
        return;
      }

      title.innerHTML = itemName;

      this.removeEventListener('mouseout', pickerGallery.restoreTitle, false);
      this.addEventListener('mouseout', pickerGallery.restoreTitle, false);

    },
    clickTab: function () {
      var itemId = this.getAttribute('data-id');
      var itemName = this.getAttribute('data-name');
      var item = pickerGallery.RootElement.querySelector('#' + itemId);
      var currentActiveItem = pickerGallery.RootElement.querySelector('.' + pickerGallery.activeItemClass);
      var currentActiveTab = pickerGallery.RootElement.querySelector('.' + pickerGallery.activeTabClass);

      currentActiveItem.classList.remove(pickerGallery.activeItemClass);
      item.classList.add(pickerGallery.activeItemClass);

      currentActiveTab.classList.remove(pickerGallery.activeTabClass);
      this.classList.add(pickerGallery.activeTabClass);

      pickerGallery.cachedTitle = itemName;


    },
    /**
     * Setup click and hover event listeners for all tab elements
     *
     * @param {NodeList} elements  - Selector for tab elements
     */
    setupTabs: function (elements) {

      if (!elements) {
        return;
      }

      for (var i = 0; i < elements.length; i++) {
        var tab = elements.item(i);

        tab.removeEventListener('click', pickerGallery.clickTab, false);
        tab.addEventListener('click', pickerGallery.clickTab, false);

        tab.removeEventListener('mouseover', pickerGallery.setTitle, false);
        tab.addEventListener('mouseover', pickerGallery.setTitle, false);

      }
    },
    /**
     * Initiate a single picker gallery
     *
     * @param {element} element  - Selector for picker gallery element
     */
    Initiate: function (element) {
      var tabsElements = element.querySelectorAll('.picker-gallery__tab');

      pickerGallery.RootElement = element;
      pickerGallery.cachedTitle = element.querySelector('.' + pickerGallery.titleSwapClass).innerHTML;
      pickerGallery.setupTabs(tabsElements);
    },
    /**
     * Initiate all picker galleries
     *
     * @param {NodeList} elements  - Selector for picker gallery elements
     */
    initiateAll: function (elements) {

      if (!elements) {
        return;
      }

      for (var i = 0; i < elements.length; i++) {
        var element = elements.item(i);
        pickerGallery.Initiate(element);
      }
    }
  };

  /**
  * Call picker gallery and initiate all elements
  */
  (function () {
    pickerGallery.initiateAll(document.querySelectorAll('.picker-gallery'));
  })();
})();
