(function () {
	'use strict';

	angular
        .module('Suzuki')
        .directive('ndCookiebar', ndCookiebar);

	function ndCookiebar() {
		controller.$inject = ["$scope"];
		var directive = {
			scope: true,
			restrict: 'A',
			controller: controller
		};
		return directive;

		// -------------------------------------------------------------------------

		/* @ngInject */
		function controller($scope) {

			// -------------------------------------------------------------------------
			// Scope
			// -------------------------------------------------------------------------

			$scope.acceptCookies = acceptCookies;
			$scope.cookiesAccepted = cookiesAccepted;


			// -------------------------------------------------------------------------
			// Functions
			// -------------------------------------------------------------------------

			function cookiesAccepted() {
				var cookie = window.docCookies.hasItem('cookiesAccepted') && window.docCookies.getItem('cookiesAccepted') === 'true';
				return cookie;
			}

			function acceptCookies() {
				window.docCookies.setItem('cookiesAccepted', 'true', Infinity, '/');
			}

		}

	}

})();
