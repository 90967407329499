(function () {
  'use strict';

  angular
    .module('Suzuki')
    .directive('navigation', navigation)
    .directive('navigationItem', navigationItem);

  /* @ngInject */
  function navigation() {
    controller.$inject = ["$window", "$document", "$element", "$timeout", "$attrs"];
    var directive = {
      controller: controller,
      restrict: 'A',
      scope: true,
      bindToController: true,
      controllerAs: 'navCtrl'
    };
    return directive;

    // -------------------------------------------------------------------------

    /* @ngInject */
    function controller($window, $document, $element, $timeout, $attrs) {
      /*jshint validthis:true */
      var ctrl = this;

      var $w = $($window);
      var $body = $document.find('body');
      var isPushStyle = $attrs.pushStyle === 'true';
      var activeDropdownId;
      var navHeight;

      ctrl.autoClose = ctrl.screenXs || isPushStyle ? 'disabled' : 'outsideClick';
      ctrl.navCollapsed = true;
      ctrl.screenXs = true;
      ctrl.dropdowns = {};

      ctrl.toggleNav = toggleNav;
      ctrl.onDropdownToggle = onDropdownToggle;

      // -------------------------------------------------------------------------

      $w.on('resize', checkProfile);

      checkProfile();

      if (ctrl.screenXs || isPushStyle) {
        var paths = $attrs.path.split(',');
        paths.shift();

        for (var i = 0; i < paths.length; i++) {
          ctrl.dropdowns[paths[i]] = true;
          onDropdownToggle(true, paths[i]);
        }
      }

      // -------------------------------------------------------------------------

      function updateAutoClose() {
        ctrl.autoClose = ctrl.screenXs || isPushStyle ? 'disabled' : 'outsideClick';
      }

      // -------------------------------------------------------------------------

      function checkProfile() {
        ctrl.screenXs = $w.width() < 768;
        navHeight = ctrl.screenXs ? 50 : 60;
        updateAutoClose();
      }

      // -------------------------------------------------------------------------

      function toggleNav() {
        ctrl.navCollapsed = !ctrl.navCollapsed;
      }

      // -------------------------------------------------------------------------

      function onDropdownToggle(open, id) {

        if (!isPushStyle || ctrl.screenXs) {
          return;
        }

        id = parseInt(id);

        if (open) {
          $timeout(function () {
            var elem = angular.element($element[0].querySelector('#d' + id));

            if (elem.length) {
              activeDropdownId = id;
              var dropdown = angular.element(elem[0].querySelector('.dropdown-pane'));
              var dropdownHeight = dropdown[0].scrollHeight;
              $body.css('padding-top', (navHeight + dropdownHeight) + 'px');
            }
          });
        } else if (activeDropdownId === id) {
          $body.css('padding-top', navHeight + 'px');
        }
      }
    }
  }

  // -------------------------------------------------------------------------

  /* @ngInject */
  function navigationItem() {
    controller.$inject = ["$scope", "$attrs"];
    var directive = {
      require: '^navigation',
      controller: controller,
      restrict: 'A',
      scope: true,
      bindToController: true,
      controllerAs: 'navItemCtrl'
    };
    return directive;

    // -------------------------------------------------------------------------

    /* @ngInject */
    function controller($scope, $attrs) {
      /*jshint validthis:true */
      var ctrl = this;
      var navCtrl = $scope.$parent.navCtrl;

      var initCollapsed = $attrs.initCollapsed === 'true';

      ctrl.initCollapsed = null;
      ctrl.isCollapsed = navCtrl.screenXs;

      ctrl.toggle = toggle;

      if (navCtrl.screenXs) {
        ctrl.isCollapsed = initCollapsed;
      }

      // -------------------------------------------------------------------------

      function toggle() {
        ctrl.isCollapsed = !navCtrl.screenXs ? false : !ctrl.isCollapsed;
      }
    }
  }
})();
