(function () {
  'use strict';

  angular
    .module('Suzuki')
    .directive('gallery', gallery);

  /* @ngInject */
  function gallery() {

    var directive = {
      link: link,
      restrict: 'A'
    };
    return directive;

    function link(scope, element, attrs) {

      var showImageText = attrs.showText === 'True';

      var $sliders = $('.flexslider.slider', element),
        $slider,
        $carousel,
        isCampaignPage,
        isCampaign,
        height;

      $sliders.each(function() {

        $slider = $(this);
        isCampaignPage = document.querySelector(".campaign-page");
        isCampaign = $slider.hasClass("campaign");

        if (isCampaignPage && !isCampaign) {
          //If carousel is on the campaign page but does not have a campaign class
          //that is used for Hero Slider
          $carousel = $(this).next('.flexslider.carousel');
          $carousel.flexslider({
            animation: 'slide',
            controlNav: false,
            directionNav: false,
            animationLoop: false,
            slideshow: false,
            itemWidth: 262.5,
            itemMargin: 30,
            asNavFor: $slider
          });
        } else if (!isCampaign) {
          $carousel = $(this).next('.flexslider.carousel');
          $carousel.flexslider({
            animation: 'slide',
            controlNav: false,
            directionNav: false,
            animationLoop: false,
            slideshow: false,
            itemWidth: 160,
            itemMargin: 0,
            asNavFor: $slider
          });
        }

        $slider.flexslider({
          animation: 'fade',
          pauseOnAction: true,
          pauseOnHover: true,
          useCSS: true,
          multipleKeyboard: true,
          controlNav: isCampaign,
          directionNav: !isCampaign,
          animationLoop: true,
          slideshow: true,
          video: true,
          sync: $carousel,
          initDelay: 2000,
          start: function(){
            if(showImageText){
              $('.info', $sliders).hide();
              $('.flex-caption', $sliders).addClass('show-text');
            }
          },
          before: function(slider){

            var currentSlide = slider.slides.eq(slider.currentSlide);
            if(!showImageText){
            currentSlide.find('.flex-caption.active').removeClass('active');
            }
            if (currentSlide.find('iframe').length !== 0) {
              pauseVideos();
            }
          }
        });

        if(!isCampaign) {
          height = Math.ceil(570 / (1024 / $slider.width()));
          $slider.css('max-height', height + 'px').find('.flex-viewport').css('max-height', height + 'px');
        } else {
          height = Math.ceil(700 / (1920 / $slider.width()));
        }

        $slider.find('.info').click(toggleInfo);

        //$slider.find('ul.slides li img').click(function() {
        $slider.find('ul.slides li div.zoom').click(function() {
          var slider = $slider.data('flexslider'),
            currentSlide = 0;

          if (slider) {
            currentSlide = slider.currentSlide;
            slider.stop();
          }

          showModal(element, currentSlide);
        });
      });

      // --------------------------------------------------------------------
      function pauseVideos(container) {
        container = container || $slider;
        container.find('li.yt > iframe').each(function() {
          var func = 'stopVideo'; //this === frame ? 'playVideo' : 'stopVideo';
          this.contentWindow.postMessage('{"event":"command","func":"' + func + '","args":""}', '*');
        });
      }

      // --------------------------------------------------------------------
      function toggleInfo() {

        /*jshint validthis: true */
        $(this).siblings('.flex-caption').toggleClass('active');
      }

      // --------------------------------------------------------------------
      function showModal(container, startAt) {

        var html = $('<div class="flexslider slider"><ul class="slides"></ul></div>'),
          width = Math.min($(window).width() - 100, 1300),
          height = Math.ceil(570 / (1024 / width)),
          images = $('.flexslider.slider ul.slides li', container)
            .clone()
            .not('.clone')
            .removeAttr('style')
            .find('div.zoom')
            .remove()
            .end();

        $('ul', html).append(images);

        Suzuki.Modal.open({
          width: width,
          height: height,
          html: html
        });

        var slider = $('#modal .flexslider').flexslider({
          startAt: startAt,
          animation: 'fade',
          pauseOnAction: true,
          pauseOnHover: true,
          multipleKeyboard: true,
          useCSS: true,
          controlNav: false,
          animationLoop: true,
          slideshow: true,
          video: true,
          before: function(slider){
            var currentSlide = slider.slides.eq(slider.currentSlide);

            currentSlide.find('.flex-caption.active').removeClass('active');

            if (currentSlide.find('iframe').length !== 0) {
              pauseVideos(slider);
            }
          }
        });

        slider.find('.info').click(toggleInfo);

        slider.css('max-height', height + 'px').find('.flex-viewport').css('max-height', height + 'px');
      }
    }
  }
})();
