(function () {
	'use strict';

	contactModuleService.$inject = ["$http"];
	angular
	  .module('Suzuki')
	  .factory('contactModuleService', contactModuleService);

	/* @ngInject */
	function contactModuleService($http) {

		var service = {
			submitInquiry: submitInquiry
		};

		return service;

		// -------------------------------------------------------------------------
		// Public
		// -------------------------------------------------------------------------

		function submitInquiry(model) {
	            return $http({
				method: 'POST',
				url: '/api/contactmodule/submit/',
				data: model,
				cache: false
			}).success(function () {
				//console.log('success', data, status);
				dataLayer.push({ 'event': 'track', 'eventCategory': 'Kontaktmodul', 'eventAction': 'Henvendelse fra Kontaktmodul', 'eventLabel': 'Kontaktmodul' });
			}).error(function (data, status) {
				console.error('error', data, status);
			});

		}

		// -------------------------------------------------------------------------
		// Private
		// -------------------------------------------------------------------------
	}
})();
