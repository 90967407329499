(function() {
  'use strict';

  Controller.$inject = ["$rootScope", "accessoriesService"];
  angular
    .module('Suzuki')
    .directive('accessoriesItem', accessoriesItem);

  function accessoriesItem() {
    var directive = {
      restrict: 'EA',
      scope: {
        cat: '@',
        title: '@',
        price: '@',
        smallprint: '@',
        ordernr: '@',
        text: '@',
        img: '@'
      },
      link: linkFunc,
      controller: Controller,
      controllerAs: 'vm',
      bindToController: true
    };

    return directive;

  }

  function linkFunc(scope, element, attr, ctrl) {
    //console.log(scope);
    element.bind('click', ctrl.openModal);

  }

  /* @ngInject */
  function Controller($rootScope, accessoriesService) {
    var vm = this;
    vm.openModal = openModal;

    //------------------------------------------------------------------------------------------
    var imageUrl = vm.img.substr(0, vm.img.indexOf('?'));
    var item = {
      'category' : vm.cat,
      'title' : vm.title,
      'price' : vm.price,
      'smallprint' : vm.smallprint,
      'text' : vm.text,
      'image': imageUrl,
      'id': vm.ordernr
    };

    accessoriesService.registerItem(item);

    //------------------------------------------------------------------------------------------

    function openModal(){
      $rootScope.$broadcast('openAccessoriesModal', {obj:item});
    }
  }
})();
