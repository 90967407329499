(function () {
  'use strict';

  scrollspy.$inject = ["$document", "$timeout"];
  angular
    .module('Suzuki')
    .directive('scrollspy', scrollspy);

  /* @ngInject */
  function scrollspy($document, $timeout) {
    var directive = {
      link: link,
      restrict: 'A'
    };
    return directive;

    function link(scope, element) {

      var active = false;
      var $handle = element.find('.handle');
      var $links = element.find('a');

      $handle.bind('click', toggle);
      $links.bind('click', toggle);

      toggle();
      $timeout(function () {
        toggle(null, true);
      }, 4000);

      scope.close = function close() {
        toggle(null, true);
      };

      function toggle(evt, close) {
        if (evt) {
          evt.stopPropagation();
        }

        if (active) {
          $document.unbind('click', toggle);
          element.removeClass('active');
          active = false;
        } else if (!close) {
          $document.bind('click', toggle);
          element.addClass('active');
          active = true;
        }
      }

      scope.$on('$destroy', function () {
        $document.unbind('click', toggle);
        $handle.unbind('click', toggle);
      });
    }
  }
})();
