(function() {
  'use strict';

  AccessoriesModalCtrl.$inject = ["$rootScope", "accessoriesService"];
  angular
    .module('Suzuki')
    .controller('AccessoriesModalCtrl', AccessoriesModalCtrl);


  /* @ngInject */
  function AccessoriesModalCtrl($rootScope, accessoriesService) {
    var vm = this;
    vm.visible = false;
    vm.isInBasket = false;
    vm.close = closeModal;
    vm.updateBasket = updateBasket;
    vm.obj = null;

    //------------------------------------------------------------------------------------------

    $rootScope.$on('openAccessoriesModal', function openmodal(event, args) {
      vm.obj = args.obj;
      //console.log(vm.obj);
      vm.visible = true;
      vm.isInBasket = accessoriesService.isInBasket(vm.obj.id);
      $rootScope.$digest();

    });

    //------------------------------------------------------------------------------------------

    function closeModal() {
      vm.visible = false;
    }

    //------------------------------------------------------------------------------------------

    function updateBasket(id) {
      if (accessoriesService.isInBasket(id)) {
        accessoriesService.removeFromBasket(id);
      } else {
        accessoriesService.addToBasket(id);
      }
      vm.isInBasket = accessoriesService.isInBasket(vm.obj.id);

      $rootScope.$broadcast('updateBasket');
    }

  }
})();
