(function () {
  'use strict';

  /*
   * Usage:
   *
   * scope.$on('breakpoints', function(event, currentBreakpoint, previousBreakpoint) {
   *   console.log(currentBreakpoint, previousBreakpoint);
   * });
   *
   * or get / watch $scope.breakpoint
   * scope.$watch('breakpoint', function (val) {
   *   console.log('breakpoint', val);
   * });
   *
   * */

  breakpoints.$inject = ["$window", "$rootScope", "$timeout"];
  angular
    .module('Suzuki')
    .directive('breakpoints', breakpoints);

  /* @ngInject */
  function breakpoints($window, $rootScope, $timeout) {
    controller.$inject = ["$scope"];
    var directive = {
      controller: controller,
      restrict: 'A'
    };
    return directive;

    /* @ngInject */
    function controller($scope) {

      var xs = 480;
      var sm = 768;
      var md = 1024;
      var lg = 1200;

      var getBreakpoint = function() {
        var windowWidth = $window.innerWidth;

        if (windowWidth < xs) {
          return 'xxs';
        } else if (windowWidth >= xs && windowWidth < sm) {
          return 'xs';
        } else if (windowWidth >= sm && windowWidth < md) {
          return 'sm';
        } else if (windowWidth >= md && windowWidth < lg) {
          return 'md';
        } else if (windowWidth >= lg) {
          return 'lg';
        }
      };

      var currentBreakpoint = getBreakpoint();
      var previousBreakpoint = null;
      var timer;

      // Broadcast inital value, so other directives can get themselves setup
      $scope.breakpoint = currentBreakpoint;

      $timeout(function() {
        $rootScope.$broadcast('breakpoints', currentBreakpoint, previousBreakpoint);
      });

      angular.element($window).bind('resize', function() {
        $timeout.cancel(timer);
        timer = $timeout(function () {
          var newBreakpoint = getBreakpoint();

          if (newBreakpoint !== currentBreakpoint || (newBreakpoint === currentBreakpoint && previousBreakpoint === null)) {
            previousBreakpoint = currentBreakpoint;
            currentBreakpoint = newBreakpoint;

            $scope.breakpoint = newBreakpoint;
            $rootScope.$broadcast('breakpoints', currentBreakpoint, previousBreakpoint);
          }
        }, 100);

      });
    }
  }
})();
