(function () {
  'use strict';

  angular
    .module('Suzuki')
    .directive('compareTo', compareTo);

  /* @ngInject */
  function compareTo() {
    var directive = {
    	link: link,
    	require: 'ngModel',
    	scope: {
    		otherModelValue: '=compareTo'
    	}
    };
    return directive;

    function link(scope, element, attributes, ngModel) {

    	ngModel.$validators.compareTo = function (modelValue) {
    		return modelValue === scope.otherModelValue;
    	};

    	scope.$watch('otherModelValue', function () {
    		ngModel.$validate();
    	});
      
    }
  }
})();
