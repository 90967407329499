(function() {
  'use strict';

  AccessoriesCtrl.$inject = ["$rootScope", "accessoriesService"];
  angular
    .module('Suzuki')
    .controller('AccessoriesCtrl', AccessoriesCtrl);

  /* @ngInject */
  function AccessoriesCtrl($rootScope, accessoriesService) {

    var vm = this;
    var filter = 'styling';
    vm.isWishlistOpen = false;
    vm.wishlistItems = [];
    vm.basketSize = 0;
    vm.addCarModel = addCarModel;
    vm.setActiveCategory = setActiveCategory;
    vm.checkActiveCategory = checkActiveCategory;
    vm.updateBasket = updateBasket;
    vm.isInBasket = isInBasket;
    vm.toggleWishlist = toggleWishlist;
    vm.removeItem = removeItem;
    vm.postwishlist = postwishlist;

   //------------------------------------------------------------------------------------------

    $rootScope.$on('updateBasket', function() {
      vm.basketSize = accessoriesService.basketSize();
    });

    //------------------------------------------------------------------------------------------

    function addCarModel(model){
      accessoriesService.addCarModel(model);
    }

    //------------------------------------------------------------------------------------------

    function setActiveCategory(filterName) {
      filter = filterName;
    }

    //------------------------------------------------------------------------------------------

    function checkActiveCategory(filterName) {
      return filter === filterName;
    }

    //------------------------------------------------------------------------------------------

    function updateBasket($event, id) {
      $event.stopPropagation();
      if (accessoriesService.isInBasket(id)) {
        accessoriesService.removeFromBasket(id);
      } else {
        accessoriesService.addToBasket(id);
      }
      vm.basketSize = accessoriesService.basketSize();
    }

    //------------------------------------------------------------------------------------------

    function isInBasket(id) {
      return accessoriesService.isInBasket(id);
    }

    //------------------------------------------------------------------------------------------


    function toggleWishlist() {
      vm.isWishlistOpen = !vm.isWishlistOpen;
      vm.wishlistItems = accessoriesService.getBasket();
    }

    function removeItem(id) {
      accessoriesService.removeFromBasket(id);
      vm.basketSize = accessoriesService.basketSize();
      vm.wishlistItems = accessoriesService.getBasket();
    }

    function postwishlist(){
        accessoriesService.postwishlist();
    }
  }
})();
