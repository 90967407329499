(function() {
    'use strict';

    angular
        .module('Suzuki')
        .directive('partialUsp1', partialUsp1);


    function partialUsp1() {
        // Usage:
        //     <usp1Partial></usp1Partial>
        // Creates:
        //
        var directive = {
            link: link,
            restrict: 'EA',
            scope: true
        };
        return directive;

        function link(scope, element) {
            var items = $(element).find('.menu li'),
			item;

            scope.activeItem = 0;

            scope.activate = function(index) {
                scope.activeItem = index;
            };

            scope.isActive = function(index) {
                return index === scope.activeItem;
            };

            scope.$watch('activeItem', function () {
                item = $(items[scope.activeItem]);
                scope.imageUrl = item.data('image');
                scope.itemText = item.find('div.content').html();
            });
        }
    }

})();
