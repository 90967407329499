(function () {
    'use strict';

    newsletterCtrl.$inject = ["$scope", "$element", "$http"];
    angular
        .module('Suzuki')
        .controller('newsletterCtrl', newsletterCtrl);


    /* @ngInject */
    function newsletterCtrl($scope, $element, $http) {
        $scope.newsletterData = [];
        $scope.newsletterData.showPayoff = false;
        $scope.newsletterData.payoffText = '';


        // -------------------------------------------------------------------------
        // Public Methods
        // -------------------------------------------------------------------------
        $scope.subscribe = subscribe;
        $scope.translateStatus = translateStatus;

        // -------------------------------------------------------------------------
        // Methods
        // -------------------------------------------------------------------------

        function subscribe(email) {
            //Send email til Suzuki.
            $http.get('/api/newsletter/signup/' + email)
              .success(function (data) {
                  $scope.newsletterData.payoffText = $scope.translateStatus(data);
                  $scope.newsletterData.showPayoff = true;

              }).error(function () {
                  $scope.newsletterData.payoffText = 'Der er desværre sket en fejl prøv igen senere.';
                  $scope.newsletterData.showPayoff = true;
              });
        }

        function translateStatus(data) {

            var message = 'Tak for din interesse i Suzukis nyhedsbrev. 	Du vil indenfor kort tid modtage en e-mail, hvor du bedes bekræfte din tilmelding.';

            if (data.indexOf('Person already registered but was inactive - sending confirmation mail') !== -1) {
                message = 'Du er allerede tilmeldt nyhedsbrevet. Hvis du ikke allerede har bekræftet tilmeldingen, vil du indenfor kort tid modtage en e-mail der giver dig mulighed for dette.';
            } else if (data.indexOf('Person already registered.') !== -1) {
                message = 'Du er allerede tilmeldt nyhedsbrevet.';
            } else if (data.indexOf('Not a valid e-mail address.') !== -1) {
                message = 'Den indtastede e-mail er ikke valid. Prøv venligst igen.';
            }

            return message;
        }
    }
})();


