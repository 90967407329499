(function () {
  'use strict';

  var Suzuki = new namespace('Suzuki'); //jshint ignore: line

  Suzuki.Modal = {
    $modal: null,
    init: function () {
      //console.log("Suzuki.Modal.init();");
      Suzuki.Modal.$modal = $('#modal');
      if (Suzuki.Modal.$modal.data('state') === 'open') {
        Suzuki.Modal.open();
      }
    },

    open: function (options) {
      //console.log("Suzuki.Modal.open();");
      //var modalMargin = 20;
      var modalHeight = false;
      var modalWidth = false;
      var closeable = true;

      if (options) {
        // Check if option width is set
        if (typeof options.width !== 'undefined') {
          modalWidth = options.width;
        }
        // Check if option height is set
        if (typeof options.height !== 'undefined') {
          modalHeight = options.height;
        }

        // Check if option closeable is set
        if (typeof options.closeable !== 'undefined') {
          closeable = false;
        }

        if (options.html) {
          $('#modal .content').empty().html(options.html);
        }
      }

      // Animate Height and Width
      if (modalWidth && modalHeight) {

        Suzuki.Modal.$modal.find('.box').css({
          'width': modalWidth + 'px',
          'height': modalHeight + 'px',
          'marginLeft': '-' + modalWidth / 2 + 'px',
          'marginTop': '-' + modalHeight / 2 + 'px'
        });
      }
      if (closeable) {
        Suzuki.Modal._bind();
      } else {
        Suzuki.Modal._unbind();
      }
      Suzuki.Modal._show();
    },

    close: function () {
      Suzuki.Modal._hide();
      //console.log("Suzuki.Modal.close();");
    },

    _show: function () {
      //console.log("show", Suzuki.Modal.$modal)
      Suzuki.Modal.$modal.stop(true, true)
        .removeAttr('style')
        .fadeIn(200);
    },
    _hide: function () {
      Suzuki.Modal.$modal.fadeOut(200, function () {
        //Suzuki.Modal.$modal.find('.flexslider').flexslider('stop');
        Suzuki.Modal.$modal.find('.content').empty();
      });
    },
    _bind: function () {
      Suzuki.Modal.$modal.addClass('closeable');
      $('#modal .inline-close, #modal .opacity, #modal .close-icon').on('click', function () {
        Suzuki.Modal.close();
        return false;
      });
    },
    _unbind: function () {
      //console.log("unbind")
      Suzuki.Modal.$modal.removeClass('closeable');
      $('#modal .inline-close, #modal .opacity, #modal .close-icon').off('click');
    }
  };
})();
