(function () {
	'use strict';

	PartialContactModuleCtrl.$inject = ["contactModuleService", "$document", "$attrs", "$scope", "Upload"];
	angular
	  .module('Suzuki')
	  .controller('PartialContactModuleCtrl', PartialContactModuleCtrl);

	/* @ngInject */
	function PartialContactModuleCtrl(contactModuleService, $document, $attrs, $scope, Upload) {
		var vm = this;

		resetForm(true);

		vm.showForm = function (e) {
			e.preventDefault();
			vm.flow.showForm = true;
			vm.flow.submitted = false;
			resetForm(false);
			vm.scrollToForm(); 
		};

		vm.scrollToForm = function() {
			$document.scrollTo($('.form-wrapper'), 61, 300);
		};

		vm.regionChange = function() {
			vm.model.mycar.dealer = 'Ej angivet';
		};
		
		vm.submitForm = function() {
			//console.log(vm.model);

			var inqTypes = $scope.$eval($attrs.inquirytypes);
			vm.model.inquiryTypeLabel = inqTypes[vm.model.inquirytype];

			Upload.upload({
				url: '/api/contactmodule/Submit?nodeid=' + vm.model.nodeid,
				data: { inquiry: angular.toJson(vm.model), file: vm.file }
			}).then(function (resp) {
				if (resp.config.data.file) {
					console.log('Success! ' + resp.config.data.file.name + ' uploaded. Response: ' + resp.data);
				} else {
					console.log('Success! Form submitted. Response: ' + resp.data);
				}
			}, function (resp) {
				console.log('Error status: ' + resp.status);
			}, function (evt) {
				if (evt.config.data.file) {
					var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
					console.log('Progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
				}
			});

			vm.flow.submitted = true;
		};

		function resetForm(firstRun) {
			vm.model = {
				inquirytype: 0,
				nodeid: $attrs.nodeId,
				mycar: {
					region: '',
					dealer: 'Ej angivet'
				}
			};

			vm.flow = {
				showForm: !firstRun,
				submitted: false
			};
		}

	}
})();
