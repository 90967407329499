(function () {
  'use strict';

  angular
    .module('Suzuki')
    .directive('rte', rte);

  /* @ngInject */
  function rte() {
    var directive = {
      link: link,
      restrict: 'C'
    };
    return directive;

    function link(scope, element) {

      var links = element.find('a:not(:has(img, span.icon-UI-arrow-right-circle))');

      links.each(function (index, link) {
        $(link).prepend('<span class="icon-UI-arrow-right-circle">&nbsp;</span>');
      });
    }
  }
})();
