(function () {
  'use strict';

  dealersFactory.$inject = ["$http"];
  angular
    .module('Suzuki')
    .factory('dealers', dealersFactory);

  /* @ngInject */
  function dealersFactory($http) {

    var service = {
      findDealers: findDealers
    };

    return service;

    // -------------------------------------------------------------------------
    // Public
    // -------------------------------------------------------------------------

    function findDealers(zipcode) {

      return $http({
        method: 'GET',
        url: '/api/dealers/GetForZipcode/' + zipcode,
        cache: true
      }).success(function() {
        //console.log('success', data, status);
        // dataLayer.push({ 'event': 'track', 'eventCategory': $scope.data.activeModelName, 'eventAction': 'Brochure bestilling gennemført', 'eventLabel': 'Brochure' });
      }).error(function(data, status) {
        console.error('error', data, status);
      });

    }

    // -------------------------------------------------------------------------
    // Private
    // -------------------------------------------------------------------------
  }
})();
