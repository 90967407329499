(function () {
  'use strict';

  dealers.$inject = ["$document", "$window", "$timeout", "uiGmapIsReady", "trackingService"];
  angular
    .module('Suzuki')
    .directive('dealers', dealers);

  /* @ngInject */
  function dealers($document, $window, $timeout, uiGmapIsReady, trackingService) {
    var directive = {
      link: link,
      restrict: 'A'
    };
    return directive;

    function link(scope, element) {
      var $w = $($window);
      var $d = $($document);
      var $map;
      var $buttonsTop;
      var $buttonsBottom;
      var dealers = [];
      var $dealer;
      var dealer;
      var infoLink;
      var geoWindow;

      var mapZoom = 7;
      var mapCenter = {
        lat: 56.163906,
        lng: 11.085204
      };

      // angular-google-maps / lodash compatability
      // http://stackoverflow.com/a/34781428
      if (typeof _.contains === 'undefined') {
        _.contains = _.includes;
      }

      if (typeof _.object === 'undefined') {
        _.object = _.zipObject;
      }

      // Map Resize
      calcMapHeight();

      $w.on('resize', applyCalcMapHeight);

      resizeHandler('.partial-dealers', function () {
        $w.trigger('scroll');
      });

      // Unbind resize event handler when directive is removed
      scope.$on('$destroy', function () {
        $w.off('resize', applyCalcMapHeight);
      });

      scope.showShops = false;

      scope.markerControl = {};
      scope.activeRegions = {};
      scope.dealers = [];
      scope.windowTemplate = '/assets/js/angular/templates/map-infowindow.html';

      scope.windowOptions = {
        pixelOffset: {
          height: -20,
          width: 0
        }
      };

      scope.map = {
        center: {
          latitude: mapCenter.lat,
          longitude: mapCenter.lng
        },
        zoom: mapZoom,
        /*
         events: {
         tilesloaded: function (map) {
         scope.$apply(function () {
         $log.log('this is the map instance', map);
         });
         }
         },
         */
        bounds: {},
        options: {
          scrollwheel: false
        }
      };

      scope.enableGeo = false;
      scope.geo = {
        id: 'geo',
        coords: {
          latitude: mapCenter.lat,
          longitude: mapCenter.lng
        },
        options: {
          icon: '/assets/images/map/geo.png',
          animation: 'DROP'
        },
        show: false
      };

      // -------------------------------------------------------------------------

      uiGmapIsReady.promise(1).then(function (instances) {

        instances.forEach(function (inst) {
          //var map = inst.map;
          //var uuid = map.uiGmap_id;
          //var mapInstanceNumber = inst.instance; // Starts at 1.
          scope.gmap = inst.map;

          parseHtml();

          if (navigator.geolocation) {
            scope.enableGeo = true;
            initGeoLocation();
          }

          calcMapHeight();

          /*
          scope.$watch(function() {
            return scope.map.bounds;
          }, function(nv, ov) {

            if (!scope.gmap || angular.equals(nv, ov)) {
              return;
            }

            var bounds = scope.gmap.getBounds();
            var dealer;
            for (var i = 0; i < scope.dealers.length; ++i) {
              dealer = scope.dealers[i];
              dealer.active = (scope.showShops || !dealer.authorized) && bounds.contains(dealer.position);
            }

          }, true);
          */

        });
      });

      // -------------------------------------------------------------------------

      scope.$watch('showShops', function (value) {

        if (scope.gmap) {
          //var bounds = scope.gmap.getBounds();
          var dealer;

          for (var i = 0; i < scope.dealers.length; ++i) {
            dealer = scope.dealers[i];

            dealer.markerOptions.visible = (value || !dealer.authorized);
            dealer.active = dealer.markerOptions.visible; // && bounds.contains(dealer.position);
          }

          scope.markerControl.newModels(scope.dealers);
        }
      });

      // -------------------------------------------------------------------------

      // prevent closing accordions on > xs
      /*
      scope.$watchCollection('activeRegions', function(newValue) {
        if (!scope.screenXs) {
          for (var key in newValue) {
            if (newValue.hasOwnProperty(key) && newValue[key] !== true) {
              newValue[key] = true;
            }
          }
        }
      });
      */

      // -------------------------------------------------------------------------

      function resizeHandler(selector, callback) {
        [].forEach.call(document.querySelectorAll(selector), function (el) {
          el.mr = [el.offsetWidth, el.offsetHeight];
          el.insertAdjacentHTML('beforeend', '<div class="mresize" style="position:absolute;width:auto;height:auto;top:0;right:0;bottom:0;left:0;margin:0;padding:0;overflow:hidden;visibility:hidden;z-index:-1"><iframe style="width:100%;height:0;border:0;visibility:visible;margin:0"></iframe><iframe style="width:0;height:100%;border:0;visibility:visible;margin:0"></iframe></div>');
          [].forEach.call(el.querySelectorAll('.mresize iframe'), function (frame) {
            (frame.contentWindow || frame).onresize = function () {
              if (el.mr[0] !== el.offsetWidth || el.mr[1] !== el.offsetHeight) {
                if (callback) {
                  callback.call(el);
                }
                el.mr[0] = el.offsetWidth;
                el.mr[1] = el.offsetHeight;
              }
            };
          });
        });
      }

      // -------------------------------------------------------------------------
      // Public Methods
      // -------------------------------------------------------------------------

      scope.click = function (marker, event, dealer) {
        scope.window = dealer;
      };

      // -------------------------------------------------------------------------

      scope.closeWindow = function () {
        scope.window = null;
      };

      // -------------------------------------------------------------------------

      scope.showAll = function () {

        scope.geo.show = false;

        var bounds = new google.maps.LatLngBounds();

        for (var i = 0; i < dealers.length; i++) {
          bounds.extend(dealers[i].position);
        }

        scope.gmap.fitBounds(bounds);
        //scope.gmap.panTo(mapCenter);
        //scope.gmap.setZoom(mapZoom);
      };

      // -------------------------------------------------------------------------

      scope.showGeo = function () {
        initGeoLocation();
      };

      // -------------------------------------------------------------------------

      scope.showDealer = function (id) {
        var dealer = $.grep(dealers, function (dealer) {
          return dealer.id === id;
        })[0];

        scope.window = dealer;
        scope.gmap.panTo(dealer.position);
        scope.gmap.setZoom(9);
        $document.scrollToElementAnimated($('#map'));
      };

      // -------------------------------------------------------------------------

      scope.isActive = function () { //(id)

        return true;

        /*
        if (scope.screenXs) {
          return true;
        }

        var dealer = $.grep(dealers, function (dealer) {
          return dealer.id === id;
        })[0];

        return dealer ? dealer.active : true;
        */
      };

      // -------------------------------------------------------------------------
      // Methods
      // -------------------------------------------------------------------------

      function calcMapHeight() {

        if (!$map || !$map.length) {
          $map = element.find('.angular-google-map-container');
          $buttonsTop = element.find('.buttons.buttons-top');
          $buttonsBottom = element.find('.buttons.buttons-bottom');
        }

        var buttonsHeight = $buttonsTop.outerHeight(true) + $buttonsBottom.outerHeight(true);

        scope.screenXs = $d.width() < 768;
        scope.mapHeight = $w.height() - 60 - 80 - 11 - 354 - buttonsHeight;

        element.css('min-height', scope.mapHeight + buttonsHeight + 22);
        $map.css('height', scope.mapHeight);
      }

      // -------------------------------------------------------------------------

      function applyCalcMapHeight() {
        scope.$apply(calcMapHeight);
      }

      // -------------------------------------------------------------------------

      function parseHtml() {

        // Regions
        /*
        if (!scope.screenXs) {
          element.find('div.level1.panel').each(function (i, region) {
            scope.activeRegions['r' + $(region).data('id')] = true;
          });
        }
        */

        // Dealers
        element.find('ul li').each(function (i, li) {
          $dealer = $(li);

          infoLink = $dealer.find('.webpage a');

          var authorized = $dealer.data('authorized').toString().toLowerCase() === 'true';
          var lat = $dealer.data('latitude');
          var lng = $dealer.data('longitude');

          dealer = {
            id: $dealer.data('id'), //name: $dealer.find('h2').text(),
            authorized: authorized,
            icon: authorized ? '/assets/images/map/marker_dealer_authorized.png' : '/assets/images/map/marker_dealer.png',
            latitude: lat,
            longitude: lng,
            position: new google.maps.LatLng(lat, lng),
            active: true,
            title: $dealer.find('h4').text(),
            address: $dealer.find('.address').html().replace(' - ', '<br />'),
            phone: $dealer.find('.phone').html(),
            link: infoLink ? infoLink.attr('href') : null,
            show: true,
            markerOptions: {
              visible: !authorized
            },
            trackEvent: trackEvent
          };

          dealers.push(dealer);
        });

        scope.dealers = dealers;
      }

      // ----------------------------------------------------------------------------
      // Zip code
      scope.alert = false;
      scope.postalCodeClick = function () {
        var isNumber = /^\d+$/.test(scope.postalCode);
        var geocoder = new google.maps.Geocoder(),
          request = {
            'address': scope.postalCode,
            componentRestrictions: {
              country: 'DK'
            }
          }
        if (scope.postalCode.length == 4 && isNumber) {
          geocoder.geocode(request, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
              scope.alert = false;
              var _lat = results[0].geometry.location.lat(),
                _lng = results[0].geometry.location.lng(),
                _newCenter = new google.maps.LatLng(_lat, _lng);
              scope.gmap.center.latitude = _lat;
              scope.gmap.center.longitude = _lng;
              scope.gmap.setZoom(12);
              scope.gmap.panTo(_newCenter);
              element.find(".zip-code-finder__input").blur();
            } else {
              scope.alert = true;
            }
          });
        } else {
          scope.alert = true;
        }
      };

      // -------------------------------------------------------------------------

      function initGeoLocation() {
        // Try HTML5 geolocation
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            var pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

            if (geoWindow) {
              geoWindow.close();
            }

            scope.geo.coords.latitude = position.coords.latitude;
            scope.geo.coords.longitude = position.coords.longitude;
            //scope.geo.position = pos;
            scope.geo.show = true;

            /*
            geoWindow = new google.maps.InfoWindow({
              map: scope.gmap,
              position: pos,
              content: 'Din position'
            });
            */

            scope.gmap.setCenter(pos);
            scope.gmap.setZoom(9);
          }, function () {
            handleNoGeolocation(true);
          });
        } else {
          // Browser doesn't support Geolocation
          handleNoGeolocation(false);
        }
      }

      // -------------------------------------------------------------------------

      function handleNoGeolocation(errorFlag) {
        var content;

        if (errorFlag) {
          content = 'Dine indstillinger tillader ikke geolokalisering.';
        } else {
          content = 'Din browser understøtter ikke geolokalisering.';
        }

        var map = scope.gmap;
        var options = {
          map: map,
          position: new google.maps.LatLng(scope.map.center.latitude, scope.map.center.longitude),
          content: content
        };

        if (geoWindow) {
          geoWindow.close();
        }

        geoWindow = new google.maps.InfoWindow(options);
        map.setCenter(options.position);
      }

      // -------------------------------------------------------------------------

      function trackEvent(category, action, optlabel) {
        trackingService.trackEvent(category, action, optlabel);
      }
    }
  }
})();
