(function (undefined) {
  'use strict';

  /**
   * @ngdoc overview
   * @name Suzuki - RecallserviceController
   * @description
   * # Suzuki
   *
   * Recallservice module of the application.
   */

  RecallserviceCtrl.$inject = ["$scope", "$http"];
  angular
    .module('Suzuki')
    .controller('RecallserviceCtrl', RecallserviceCtrl);

  /* @ngInject */
  function RecallserviceCtrl($scope, $http) {

    $scope.recallservice = {};
    $scope.recallservice.showPayoff = false;
    $scope.recallservice.loadingData = false;

    $scope.checkNr = function () {
      $scope.recallservice.showPayoff = false;
      $scope.recallservice.loadingData = true;

      $http.get('/api/chassiscampaign/check/' + $scope.recallservice.nr)
        .success(function (data) {
          $scope.recallservice.loadingData = false;
          //console.log(data);
          $scope.recallservice.showPayoff = true;
          $scope.recallservice.response = createResponse(data);
        }).error(function () {
          $scope.recallservice.loadingData = false;
          $scope.recallservice.showPayoff = true;
          $scope.recallservice.response = '<p>der er desværre sket en fejl, prøv igen senere.</p>';
        });
    };

    function createResponse(data){
      var response = '<p>Model med Stelnummeret: ' + data.chassisnr + '</p>';
      switch (data.textkode)
      {
      case '0':
        response += '<p>Der opstod en fejl. Prøv igen</p>';
        break;
      case '1':
        response += '<p>Serveren svarer ikke i øjeblikket. Prøv igen senere</p>';
        break;
      case '2':
        response += '<p>Stelnummeret findes ikke. Check at det er indtastet korrekt</p>';
        break;
      case '3':
        response += '<p>Kampagne er allerede udført</p>';
        break;
      case '4':
        response += '<p>Kontakt din Suzuki forhandler, for at få udført kampagne</p>';
        break;
      case '5':
        response += '<p>Bilen er ikke omfattet af nogen kampagner</p>';
        break;
      case '6':
        response += '<p>Check at stelnummer er indtastet korrekt</p>';
        break;
      default:
        response += '<p>Der opstod en fejl. Prøv igen</p>';
        break;
      }

      return response;
    }
  }
})();

/*
Stelnumre UDEN kampagne til test:

TSMEYA21S00639183
TSMNZC72S00323925
JSAJTD54V00223141

Stelnummer med kampagne til test:

JSAJTD44V00117910
*/
