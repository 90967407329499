(function (undefined) {
  'use strict';

  /**
   * @ngdoc overview
   * @name Suzuki - StickyQController
   * @description
   * # Suzuki
   *
   * StickyQ module of the application.
   */

  StickyQCtrl.$inject = ["$scope", "$timeout", "localStorageService"];
  angular
    .module('Suzuki')
    .controller('StickyQCtrl', StickyQCtrl);

  /* @ngInject */
  function StickyQCtrl($scope, $timeout, localStorageService) {

    var showDelay = 30000;
    var hideDelay = 30000;
    var storageKey = 'PopupDate';

    var today = new Date().setHours(0,0,0,0);
    var storedDate = localStorageService.get(storageKey);

    $scope.popupActive = false;

    $scope.close = function() {
      $scope.popupActive = false;
    };

    if (storedDate) {
      var lastVisit = new Date(storedDate).setHours(0,0,0,0);

      if (lastVisit === today) {
        return;
      }
    }

    $timeout(function() {
      //console.log('timeout showPopup');
      showPopup();
      localStorageService.set(storageKey, new Date());
    }, showDelay);

    function showPopup() {
      $scope.popupActive = true;

      $timeout(function() {
        if ($scope.popupActive) {
          //console.log('timeout hidePopup');
          hidePopup();
        }
      }, hideDelay);
    }

    function hidePopup() {
      $scope.popupActive = false;
    }
  }
})();

