(function () {
  'use strict';

  var accordion;

  accordion = {
    RootElement: '',
    tabFocusClass: 'accordion__tab--focus',
    tabActiveClass: 'accordion__tab--active',
    clickTab: function () {
      var previousFocusTab = accordion
        .RootElement
        .querySelector('.' + accordion.tabFocusClass);
      var tabIsFocus = this.classList.contains(accordion.tabFocusClass);
      var panel = this.nextElementSibling;

      //Check for removal of previous focus reset
      if (previousFocusTab && !tabIsFocus) {
        previousFocusTab.classList.remove(accordion.tabFocusClass);
      }

      this.classList.toggle(accordion.tabActiveClass);
      this.classList.toggle(accordion.tabFocusClass);

      //Set accordion height
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + 'px';
      }
    },
    /**
     * Initiate a single accordion
     *
     * @param {element} element  - Selector for lighbox element
     */
    Initiate: function (element) {
      var tabs = element.querySelectorAll('.accordion__tab');
      accordion.RootElement = element;

      for (var i = 0; i < tabs.length; i++) {
        var tab = tabs.item(i);

        tab.removeEventListener('click', accordion.clickTab, false);
        tab.addEventListener('click', accordion.clickTab, false);
      }
    },
    /**
     * Initiate all accordions'
     *
     * @param {NodeList} elements  - Selector for accordion elements
     */
    initiateAll: function (elements) {

      if (!elements) {
        return;
      }

      for (var i = 0; i < elements.length; i++) {
        var element = elements.item(i);
        accordion.Initiate(element);
      }
    }
  };

  /**
  * Call accordion and initiate all elements
  */
  (function () {
    accordion.initiateAll(document.querySelectorAll('.accordion'));
  })();
})();

