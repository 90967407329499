(function () {
    'use strict';

    modelNewsLetterCtrl.$inject = ["$scope", "$element", "$http"];
    angular
        .module('Suzuki')
        .controller('modelNewsLetterCtrl', modelNewsLetterCtrl);


    /* @ngInject */
    function modelNewsLetterCtrl($scope, $element, $http) {
        $scope.modelNewsletterData = [];
        $scope.modelNewsletterData.showPayoff = false;
        $scope.modelNewsletterData.payoffText = '';
        $scope.modelNewsletterData.errorText = 'Indtast navn & gyldig e-mailadresse.';

        $scope.subscribe = function (user, unit) {
            //Send email til Suzuki.
            user.unit = unit;

            $http.post('/api/newsletter/modelsignup/', user)
            .success(function (data) {
                //console.log(data, status);
                $scope.modelNewsletterData.payoffText = $scope.translateStatus(data);
                $scope.modelNewsletterData.showPayoff = true;

            }).error(function (data, status) {
                console.log(data, status);
                $scope.modelNewsletterData.payoffText = 'Der er desværre sket en fejl prøv igen senere.';
                $scope.modelNewsletterData.showPayoff = true;

            });



        };

        $scope.translateStatus = function(data) {

            //var message = 'Tak for din interesse for Suzukis nyhedsbrev. Du vil indenfor kort tid modtage en bekræftelse på din tilmelding på den oplyste e-mail.';
            var message = 'Tak for din tilmelding. Du vil snart høre fra os.';
            console.log(data);

            /*
            if (data.indexOf("Person already registered but was inactive - sending confirmation mail") != -1) {
                message = 'Du er allerede tilmeldt nyhedsbrevet. Hvis du ikke allerede har bekræftet tilmeldingen, vil du indenfor kort tid modtage en e-mail der giver dig mulighed for dette.';
            } else if (data.indexOf("Person already registered.") != -1) {
                message = 'Du er allerede tilmeldt nyhedsbrevet.';
            }

            else if (data.indexOf("Not a valid e-mail address.") != -1) {
                message = 'Den indtastede e-mail er ikke valid. Prøv venligst igen.';
            }
            */
            return message;
        };
    }
})();
