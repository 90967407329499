(function() {
  'use strict';

  angular
    .module('Suzuki')
    .factory('accessoriesService', accessoriesService);

  /* @ngInject */
  function accessoriesService() {
    var carmodel = '';
    var basket = [];
    var items = [];
    var service = {

      addCarModel: addCarModel,
      addToBasket: addToBasket,
      removeFromBasket: removeFromBasket,
      getBasket: getBasket,
      registerItem: registerItem,
      isInBasket: isInBasket,
      basketSize: basketSize,
      postwishlist: postwishlist
    };

    return service;

    function addCarModel(model) {
      carmodel = model;
    }

    //------------------------------------------------------------------------------------------

    function registerItem(obj) {
      items.push(obj);
    }

    //------------------------------------------------------------------------------------------

    function isInBasket(id) {
      return basket.indexOf(id) !== -1;
    }

    //------------------------------------------------------------------------------------------

    function addToBasket(id) {
      basket.push(id);
    }

    //------------------------------------------------------------------------------------------

    function removeFromBasket(id) {
      var index = basket.indexOf(id);
      if (index > -1) {
        basket.splice(index, 1);
      }
    }

    //------------------------------------------------------------------------------------------

    function getBasket() {
      var l = basket.length;
      var itemsCount = items.length;
      var wishlist = [];
      for (var i = 0; i < l; i++) {
        wishlist.push(findItem(basket[i], itemsCount));
        // more statements
      }
      return wishlist;
    }

    function findItem(id, count) {
      for (var j = 0; j < count; j++) {

        if (items[j].id === id) {
          return items[j];
        }

      }
      return null;
    }

    //------------------------------------------------------------------------------------------

    function basketSize() {
      return basket.length;
    }

    //------------------------------------------------------------------------------------------


    function postwishlist() {
      console.log('post');
      var json = {
        model: carmodel,
        items: []
      };

      var wishlist = getBasket();
      var category;
      var item;
      var listItem;
      var done;


      for (var i = 0; i < wishlist.length; i++) {
        listItem = wishlist[i];
        done = false;

        item = {
          'title': listItem.title,
          'ordernr': listItem.id,
          'price': listItem.price
        };

        for (var j = 0; j < json.items.length; j++) {
          if (json.items[j].title === listItem.category) {
            json.items[j].items.push(item);
            done = true;
            break;
          }
        }

        if (done) {
          continue;
        }

        category = {
          title: listItem.category,
          items: []
        };
        category.items.push(item);
        json.items.push(category);
      }

      var form = $('<form id="basketForm" method="post" enctype="application/json" action="/api/accessories/generatepdf"><input type="hidden" name="basket" value="" /></form>');
      form.find('input[name="basket"]').val(JSON.stringify(json));
      form.appendTo($('body')).submit().remove();

    }
  }
})();
