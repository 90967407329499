(function () {
  'use strict';

  comparatorFactory.$inject = ["$http"];
  angular
    .module('Suzuki')
    .factory('comparator', comparatorFactory);

  /* @ngInject */
  function comparatorFactory($http) {

    var service = {
      getData: getData
    };

    return service;

    // -------------------------------------------------------------------------
    // Public
    // -------------------------------------------------------------------------

    function getData(baseUrl) {
      var comparisonUrl = baseUrl;
      // Note: Quickfix
      if ((baseUrl.match('^http://s') || baseUrl.match('^https://s')) && location.protocol === 'https:') {
        comparisonUrl = baseUrl.replace("https://s","https://www.s");
      }
      else if ((baseUrl.match('^http://s') || baseUrl.match('^https://s')) && location.protocol === 'http:') {
        comparisonUrl = baseUrl.replace("http://s","http://www.s");
      }

      return $http({
        method: 'GET',
        url: comparisonUrl + 'PartialSubmodelComparisonJson/',
        cache: true
      }).success(function() {
        //console.log('success', data, status);
      }).error(function(data, status) {
        console.error('error', data, status);
      });
    }

    // -------------------------------------------------------------------------
    // Private
    // -------------------------------------------------------------------------
  }
})();
