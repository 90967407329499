(function() {
    'use strict';

    angular
        .module('Suzuki')
        .directive('partialEquipment', partialEquipment);

    function partialEquipment() {
      var directive = {
          link: link,
          restrict: 'EA',
          scope: true
      };
      return directive;

      function link(scope, element) {
          var items = $(element).find('.menu li.btn'),item;
          scope.activeItem = 0;

          scope.activate = function(index) {
              scope.activeItem = index;
          };

          scope.isActive = function(index) {
              return index === scope.activeItem;
          };

          scope.$watch('activeItem', function () {
              item = $(items[scope.activeItem]);
              scope.imageUrl = item.data('image');
              scope.itemContent = item.find('.content').html();
          });
      }
  }
})();
