(function () {
    'use strict';

    bookServiceFunction.$inject = ["$http"];
    angular
        .module('Suzuki')
        .factory('bookService', bookServiceFunction);

    /* @ngInject */
    function bookServiceFunction($http) {

        var service = {
            book: book
        };

        return service;

        // -------------------------------------------------------------------------
        // Public
        // -------------------------------------------------------------------------

        function book(formData) {
            return $http({
                method: 'POST',
                url: '/api/bookserviceapi/SubmitForm',
                data: formData,
                cache: false
            }).success(function () {
                //console.log('success', data, status);

            }).error(function (data, status) {
                console.error('error', data, status);
            });
        }

        // -------------------------------------------------------------------------
        // Private
        // -------------------------------------------------------------------------
    }
})();
