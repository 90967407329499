(function () {
  'use strict';

  comparator.$inject = ["$window", "$timeout"];
  sizer.$inject = ["$timeout"];
  angular
    .module('Suzuki')
    .directive('comparator', comparator)
    .directive('sizer', sizer);

  // -------------------------------------------------------------------------
  // Comparator
  // -------------------------------------------------------------------------

  /* @ngInject */
  function comparator($window, $timeout) {
    controller.$inject = ["$scope", "$element", "$attrs", "comparator"];
    var directive = {
      replace: true,
      scope: {
        baseUrl: '@comparator'
      },
      templateUrl: '../assets/js/angular/templates/partial-comparator.html',
      restrict: 'A',
      link: link,
      controller: controller,
      controllerAs: 'comp',
      bindToController: true
    };
    return directive;

    // -------------------------------------------------------------------------

    function link(scope, element) {

      var $w = $($window);
      var $top = element.find('.top');
      var $bottom = element.find('.bottom');
      var $table = element.find('table.table');
      var $tableScroll = element.find('.table-scroll');
      var $header = element.find('thead.header');
      var $headerLabel = $header.find('.fix');

      var fixed = false;
      var isFixed = false;
      var leftScroll = 0;

      $w.on('scroll', onPageScroll);
      $tableScroll.on('scroll', onTableScroll);

      scope.$on('$destroy', function() {
        $w.off('scroll', onPageScroll);
        $tableScroll.off('scroll', onTableScroll);
      });

      // -------------------------------------------------------------------------

      scope.toggleRow = function(title) {
        scope.comp.groups[title] = !scope.comp.groups[title];

        if (isFixed) {
          recalculateWidths();
        }
      };

      // -------------------------------------------------------------------------

      scope.scrollLeft = function() {
        var $headers = $table.find('thead.header th').not('.fix, .invisible').find('div');
        var scroll = $tableScroll.scrollLeft();
        var target = 0;

        var widths = $headers.map(function(){
          return $(this).parent().outerWidth();
        });

        widths.each(function (i, elem) {
          /* - 1 is added to scroll because IE, Edge, Firefox and Chrome
             calculate table width differently, this ensures that the value is actually higher */
          if (target + elem >= scroll - 1) {
            $tableScroll.scrollLeftAnimated(target, 300);
            return false;
          }
          target += elem;
        });
      };

      // -------------------------------------------------------------------------

      scope.scrollRight = function() {
        var $headers = $table.find('thead.header th').not('.fix, .invisible').find('div');
        var headerLabelWidth = $headerLabel.outerWidth();
        var tableWidth = $tableScroll.outerWidth();
        var scrollWidth = tableWidth - headerLabelWidth;
        var scroll = (Math.round($tableScroll.scrollLeft() * 10) / 10); 
        var target = 0;

        var widths = $headers.map(function(){
          return (Math.round($(this).parent().outerWidth() * 10) / 10);
        });
        widths.each(function (i, width) {
          target += width;
          var targetRounded = (Math.round(target * 10) / 10);
          /* + 1 is added to scrollWidth because IE, Edge, Firefox and Chrome
             calculate table width differently, this ensures, that the value is actually higher */
          if (targetRounded - scroll > scrollWidth + 1) {
            $tableScroll.scrollLeftAnimated(target - scrollWidth, 300);
            return false;
          }
        });
      };

      // -------------------------------------------------------------------------

      function onPageScroll() {
        fixed = $top.hasClass('ui-scrollpoint') && !$bottom.hasClass('ui-scrollpoint');

        if (fixed && !isFixed) {
          isFixed = true;
          enableFixed();
        } else if (!fixed && isFixed) {
          isFixed = false;
          disableFixed();
        }
      }

      // -------------------------------------------------------------------------

      function onTableScroll() {
        if (isFixed) {
          leftScroll = $tableScroll.scrollLeft();
          $header.scrollLeft(leftScroll);
          $headerLabel.css('left', leftScroll);
        }
      }

      // -------------------------------------------------------------------------

      function enableFixed() {
        var $headers = $table.find('thead.header th').not('.fix, .invisible').find('div');
        var $footers = $table.find('tbody.price th').not('.fix, .invisible').find('> div');

        var widths = $footers.map(function(){
          return $(this).width();
        }).get();

        $headers.each(function (i, elem) {
          $(elem).width(widths[i]);
        });

        $footers.each(function (i, elem) {
          $(elem).width(widths[i]);
        });

        $table.addClass('fixed');
        onTableScroll();
      }

      // -------------------------------------------------------------------------

      function disableFixed() {
        var $headers = $table.find('thead.header th').not('.fix, .invisible').find('div');
        var $footers = $table.find('tbody.price th').not('.fix, .invisible').find('> div');

        $headers.each(function (i, elem) {
          $(elem).width('auto');
        });

        $footers.each(function (i, elem) {
          $(elem).width('auto');
        });

        $table.removeClass('fixed');
        $headerLabel.css('left', 0);
      }

      // -------------------------------------------------------------------------

      function recalculateWidths() {
        var $headers = $table.find('thead.header th').not('.fix, .invisible').find('div');
        var $footers = $table.find('tbody.price th').not('.fix, .invisible').find('> div');

        $footers.each(function (i, elem) {
          $(elem).width('auto');
        });

        $timeout(function () {
          var widths = $footers.map(function(){
            return $(this).width();
          }).get();

          $headers.each(function (i, elem) {
            widths[i] = Math.max(widths[i], $(elem).width());
            $(elem).width(widths[i]);
          });

          $footers.each(function (i, elem) {
            $(elem).width(widths[i]);
          });

          onTableScroll();
        });
      }
    }

    // -------------------------------------------------------------------------

    /* @ngInject */
    function controller($scope, $element, $attrs, comparator) {
      /*jshint validthis: true */
      var comp = this;

      comp.groups = {
        'Sikkerhed': true,
        'Komfort/funktionelt': true,
        'Design/praktik': true
      };

      comp.count = 0;

      // -------------------------------------------------------------------------

      $scope.$watch('comp.filters', function (filters) {
        var evaluate;
        var modelExclude;
        var filterExclude;
        var count = 0;

        if (filters) {
          $.each(filters, function(i, filter) {
            evaluate = false;

            $.each(filter.values, function(i, value) {
              evaluate = evaluate || value.selected;
            });

            filter.evaluate = evaluate;
          });

          $.each(comp.models, function(i, model) {
            modelExclude = false;

            $.each(filters, function(i, filter) {
              if (filter.evaluate) {
                filterExclude = true;
                $.each(filter.values, function(key, value) {
                  //if (value && model.features[filter.index].value === key) {
                  if (value.selected && model.features[filter.index].value === value.title) {
                    filterExclude = false;
                  }
                });
                modelExclude = modelExclude || filterExclude;
              }
            });

            model.show = !modelExclude;

            if (model.show) {
              count++;
            }
          });
          comp.count = count;

          $scope.$broadcast('filtered', {});
        }
      }, true);

      // -------------------------------------------------------------------------

      comparator.getData(comp.baseUrl).then (
        function(result) {

          var filters = {};
          var filter;
          var item;
          var prevalue;

          if (result.data.filters) {
            for (var i = 0; i < result.data.filters.length; i++) {
              item = result.data.filters[i];

              filter = {
                name: item.name,
                alias: item.alias,
                index: i,
                values: []
              };

              for (var j = 0; j < item.preValues.length; j++) {
                prevalue = {};
                prevalue.title = item.preValues[j];
                prevalue.index = j;
                prevalue.selected = item.preValues[j] === result.data.defaultFilters[filter.alias];

                //preselected = item.preValues[j] === result.data.defaultFilters[filter.alias];
                //filter.values[item.preValues[j]] = preselected;

                filter.values.push(prevalue);
              }

              filters[item.alias] = filter;
            }
          }

          comp.filters = filters;
          comp.models = result.data.models;
        }
      );
    }
  }

  // -------------------------------------------------------------------------
  // Sizer
  // -------------------------------------------------------------------------

  function sizer($timeout) {
    var directive = {
      restrict: 'A',
      link: link,
      scope: {
        sizer: '=',
        sizerPadding: '=',
        sizerFilterUpdate: '='
      }
    };
    return directive;

    function link(scope, element) {

      var padding = scope.sizerPadding !== undefined ? scope.sizerPadding : 20;

      // Microsoft Edge calculates heights differently ?!?
      if (/ Edge\/\d+/.test(navigator.userAgent)) {
        padding += 1;
      }

      scope.$watch('sizer', function (newValue) {
        if (newValue === true) {
          checkSize();
        }
      });

      scope.$on('filtered', function(){
        if (scope.sizerFilterUpdate) {
          checkSize();
        }
      });

      function checkSize() {
        $timeout(function () {
          element.height(element.parent('tr').innerHeight() - padding);
        });
      }

      checkSize();
    }
  }
})();
